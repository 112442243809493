@import "../variables.module.scss";

.intercom-custom-button-container {
  border-radius: 100%;
  width: 42px;
  height: 42px;
  background: #0077db;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 25px;
  right: 30px;
  z-index: 999;
  box-shadow:
    rgba(0, 0, 0, 0.15) 0px 2px 5px 0px,
    rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
  cursor: pointer;
  &.chat-open {
    right: 400px;
  }

  svg {
    fill: #fff;
    width: 23px;
    height: 23px;
    path {
      fill: #fff;
    }
  }

  &:hover {
    transform: scale(1.2);
  }

  @media screen and (max-width: 1100px) {
    display: none;
  }
}
